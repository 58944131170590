import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade-down" }, {
    default: _withCtx(() => [
      (_ctx.State)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["notification", 'type-' + _ctx.type])
          }, _toDisplayString(_ctx.message), 3))
        : _createCommentVNode("", true)
    ], undefined, true),
    _: 1
  }))
}