import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "validate"
}
const _hoisted_2 = { class: "validate__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade-down" }, {
    default: _withCtx(() => [
      (_ctx.State)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("a", {
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.Validate(false)), ["prevent"])),
                href: "#"
              }, "Decline"),
              _createElementVNode("a", {
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.Validate(true)), ["prevent"])),
                href: "#"
              }, "Approve")
            ])
          ]))
        : _createCommentVNode("", true)
    ], undefined, true),
    _: 1
  }))
}