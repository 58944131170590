
import { Vue } from "vue-class-component";
import { Events } from "@utils/Events";

export default class ValidatePopup extends Vue {
    State = false;

    created() {
        this.emitter.on(Events.ValidateStart, () => {
            this.State = true;
        });
    }

    public Validate(flag: boolean) {
        this.State = false;
        this.emitter.emit(Events.ValidateStop, flag);
    }
}
