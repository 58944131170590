import client from "@utils/client";

export default {
    verify: function (callback: any) {
        return client.get("/umbraco/app/auth/verifyrequest", function (res: any) {
            callback(res);
            if (res.customer.features != undefined) {
                localStorage.setItem("session_features", JSON.stringify(res.customer.features));
            } else {
                localStorage.setItem("session_features", JSON.stringify([]));
            }
        });
    },
    VerifyAdmin: function (callback: any) {
        return client.get("/umbraco/admin/auth/verifyrequest", function (res: any) {
            callback(res);
        }, true);
    },
    VerifyDevAdmin: function (callback: any) {
        return client.get("/umbraco/admin/auth/shadowlogin?key=93b997b4-2730-4e6d-abc1-15823a00d9b6&mail=nibg@generaxion.dk&verification=8afcfe59-2725-4080-90c0-81a542fb7558", function (res: any) {
            document.cookie = "yourAuthCookie="+ res +"; Domain=.shopify.generaxion.tech; Path=/; Expires=Sat, 20 Aug 2022 13:02:59 GMT;";
            callback(res);
        });
    }
}