
import { Vue, Options } from 'vue-class-component'
import Notification from "@/components/blocks/Notification.vue";
import Loading from "@/components/blocks/Loading.vue";
import ValidatePopup from "@/components/blocks/ValidatePopup.vue";

@Options({
    components: {
        Notification,
        Loading,
        ValidatePopup
    }
})
export default class App extends Vue {}
