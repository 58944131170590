import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AppBridge from "@shopify/app-bridge";
import { History, TitleBar } from '@shopify/app-bridge/actions';
import auth from "@utils/auth";
import client from "@utils/client";

import BaseTemplate from "@/templates/BaseTemplate.vue";
import AdminTemplate from "@/templates/AdminTemplate.vue";
import CleanTemplate from "@/templates/CleanTemplate.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    component: BaseTemplate,
    meta: {
      requiresBasicAuth: true
    },
    children: [
      {
        path: "/products",
        name: "Produkter",
        component: () => import(/* webpackChunkName: "products" */ "@/views/Products.vue")
      },
      {
        path: "/navision",
        component: () => import(/* webpackChunkName: "navision" */ "@/views/navision/Navision.vue"),
        children: [
          {
            path: "",
            name: "Navision Settings",
            component: () => import(/* webpackChunkName: "navision-settings" */ "../views/navision/Settings.vue")
          }
        ]
      },
      {
        path: "/economic",
        component: () => import(/* webpackChunkName: "economic" */ "../views/economic/Economic.vue"),
        children: [
          {
            path: "",
            name: "E-conomic Settings",
            component: () => import(/* webpackChunkName: "economic-settings" */ "../views/economic/Settings.vue")
          },
          {
            path: "accounting",
            name: "E-conomic Accounting",
            component: () => import(/* webpackChunkName: "economic-accounting" */ "../views/economic/Accounting.vue")
          },
          {
            path: "inventory",
            name: "E-conomic Inventory",
            component: () => import(/* webpackChunkName: "economic-accounting" */ "../views/economic/Inventory.vue")
          }
        ]
      },
    ]
  },
  {
    path: "",
    component: AdminTemplate,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "/admin",
        name: "Shops",
        component: () => import(/* webpackChunkName: "admin-dashboard" */ "../views/admin/Shops.vue"),
      },
      {
        path: "/admin/features",
        name: "Features",
        component: () => import(/* webpackChunkName: "admin-features" */ "../views/admin/Features.vue"),
      },
      {
        path: "/admin/install",
        name: "Install",
        component: () => import(/* webpackChunkName: "admin-features" */ "../views/admin/Install.vue"),
      }
    ]
  },
  {
    path: "",
    component: CleanTemplate,
    children: [
      {
        path: "/login",
        name: "AdminLogin",
        component: () => import(/* webpackChunkName: "admin-login" */ "../views/admin/AdminLogin.vue")
      }
    ]
  },
  { 
    path: '/404',
    component: () => import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue")
  },  
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/404',
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const headers = client.GetSearchQuery();
let history: any,
    app: any,
    titleBarOptions = {};

if (headers.shop != undefined) {
  app = AppBridge({
    "apiKey": 'f6e5543b97da7e2bb9ceef7ed29ce14b',
    "shopOrigin": headers.shop
  });
  
  history = History.create(app);
}

function hasQueryParams(route: any) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  if (process.env.NODE_ENV == 'production') {
    if (to.matched[0].meta.requiresBasicAuth) {
      auth.verify(function (res: any) {
        if (res.status == 200) {
          history.dispatch(History.Action.PUSH, to.path);
          
          titleBarOptions = {
            title: to.name,
          };
          const myTitleBar = TitleBar.create(app, titleBarOptions);
          
          if(!hasQueryParams(to) && hasQueryParams(from)){
            next({ path: to.path, query: from.query });
          } else {
            next()
          }
        } else {
          window.location = res.redirect;
        }
      });
    } else if (to.matched[0].meta.requiresAuth) {
      auth.VerifyAdmin(function (res: any) {
        if (res.Status == true) {
          next();
        } else {
          next({ name: "AdminLogin", query: { error: "true" }})
        }
      });
    } else {
      next();
    }
  }else {
    next();
  }
});

export default router;
