import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_Notification = _resolveComponent("Notification")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_ValidatePopup = _resolveComponent("ValidatePopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RouterView),
    _createVNode(_component_Notification),
    _createVNode(_component_Loading),
    _createVNode(_component_ValidatePopup)
  ]))
}