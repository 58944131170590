
import { Vue } from 'vue-class-component';

export default class App extends Vue {
  features: { name: string; label: string }[] = [];
  FilteredFeatures: { name: string; label: string }[] = [];

  beforeMount() {
    // const features = localStorage.getItem('session_features');
    // this.features = features !== null ? JSON.parse(features) : [];
    this.features = [
      {
        name: "price-changer",
        label: "Pris regler"
      },
      {
        name: "economic",
        label: "E-conomic"
      }
    ]
  }
}
