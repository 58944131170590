export default {
    post: function (url: string, object: any, callback: any, isAdmin = true) {
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
                callback(JSON.parse(this.responseText));
            } else if (this.readyState == 4 && this.status != 200) {
                console.log(this.responseText);
            }

            console.log(this)
        };
        if (process.env.NODE_ENV == 'development') {
            url = "https://shopify.generaxion.tech" + url;
            isAdmin = true;
        }
        const headers = this.GetSearchQuery();
        if ((headers.hmac != undefined && headers.shop != undefined && headers.timestamp != undefined) || isAdmin) {
            xhr.open("POST", url, true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            if (process.env.NODE_ENV == 'production') {
                xhr.setRequestHeader("x-shopify-hmac", headers.hmac);
                xhr.setRequestHeader("x-shopify-shop", headers.shop);
                xhr.setRequestHeader("x-shopify-timestamp", headers.timestamp);
            }
            xhr.send(JSON.stringify(object));
        } else {
            console.log('Error');
        }
    },
    put: function (url: string, object: any, callback: any, isAdmin = true) {
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
                callback(JSON.parse(this.responseText));
            } else if (this.readyState == 4 && this.status != 200) {
                console.log('error');
            }
        };
        if (process.env.NODE_ENV == 'development') {
            url = "https://shopify.generaxion.tech" + url;
            isAdmin = true;
        }
        const headers = this.GetSearchQuery();
        if ((headers.hmac != undefined && headers.shop != undefined && headers.timestamp != undefined) || isAdmin) {
            xhr.open("PUT", url, true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            if (process.env.NODE_ENV == 'production') {
                xhr.setRequestHeader("x-shopify-hmac", headers.hmac);
                xhr.setRequestHeader("x-shopify-shop", headers.shop);
                xhr.setRequestHeader("x-shopify-timestamp", headers.timestamp);
            }
            xhr.send(JSON.stringify(object));
        } else {
            console.log('Error');
        }
    },
    get: function (url: string, callback: any, isAdmin = false) {
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function() {
            if (this.status != 200 && this.readyState == 4) {
                callback(this);
                return;
            }
            if (this.readyState == 4) {
                callback(JSON.parse(this.responseText));
            }
        };
        if (process.env.NODE_ENV == 'development') {
            url = "https://shopify.generaxion.tech" + url;
            isAdmin = true;
        }
        const headers = this.GetSearchQuery();
        if ((headers.hmac != undefined && headers.shop != undefined && headers.timestamp != undefined) || isAdmin) {
            const hmac = headers.hmac;
            delete headers.hmac;
            let VerifyString = "";
            for (const key in headers) {
                if (Object.prototype.hasOwnProperty.call(headers, key)) {
                    const element = headers[key];
                    VerifyString += key + "=" + element + "&";
                }
            }
            xhr.open("GET", url, true);
            if (process.env.NODE_ENV == 'production') {
                xhr.setRequestHeader("x-shopify-shop", headers.shop);
                xhr.setRequestHeader("x-shopify-hmac", hmac);
                xhr.setRequestHeader("x-shopify-verify-string", VerifyString.replace(/&+$/,''));
            }
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.setRequestHeader("Accept", "application/json");
            xhr.send();
        } else {
            console.log('Error');
        }
    },
    GetSearchQuery: function () {
        if (window.location.search != "" || window.location.hash.indexOf("?") != -1) {
            const QueryString: string[] = window.location.search != "" ? window.location.search.replace('?', '').split('&') : window.location.hash.split('?')[1].split('&');
            const result: { [key: string]: string } = {};
            QueryString.forEach(query => {
                const tmp = query.split('=');
                result[tmp[0]] = tmp[1];
            });
            return result;
        } else {
            return {}
        }
    }
}